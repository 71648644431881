/*
 * Typography
 *
 * Website-wide text styling
 *
 */

@import 'variables.scss';

@import url('https://fonts.googleapis.com/css2?family=Anton&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');
* {
	font-size: inherit;
	font-weight: inherit;
  color: inherit;
  vertical-align: inherit;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $body-font;
}

svg {
  height: 1em;
  width: auto;
}

a {
	color: inherit;
	text-decoration: none;
}

strong {
  font-weight: bold;
}

 h1,  h2,  h3,  h4, blockquote,
.h1, .h2, .h3, .h4 {
	margin: 0px;
}

p {
	margin: 0px;
}

p + p {
	margin-top: .75em;
}

li + li {
  margin-top: .5em;
}

p + ul,
ul + p {
  margin-top: 1em;
}

:global(.about) h4 {
  color: $gray-medium;
  font-size: 14px;
}

h1, :global(.h1)    {font-size: $h1-mobile}
h2, :global(.h2)    {font-size: $h2-mobile}
h3, :global(.h3)    {font-size: $h3-mobile}
h4, :global(.h4)    {font-size: $h4-mobile}
h5, :global(.h5)    {font-size: $h5-mobile}
h6, :global(.h6)    {font-size: $h6-mobile}
p, :global(.p)      {font-size: $p-mobile}
body                {font-size: $p-mobile}

h1, :global(.h1)    {line-height: 1.2em}
h2, :global(.h2)    {line-height: 1.2em; margin-bottom: .25em;}
h3, :global(.h3)    {line-height: 1.2em; margin-bottom: .25em;}
h4, :global(.h4)    {line-height: 1.2em}
h5, :global(.h5)    {line-height: 1.3em}
h6, :global(.h6)    {line-height: 1.3em}
p, :global(.p)      {line-height: 1.3em}
body                {line-height: 1.3em}

h1, :global(.h1)    {font-family: $display-font; text-transform: uppercase}
h2, :global(.h2)    {font-family: $display-font; text-transform: uppercase}
h3, :global(.h3)    {font-family: $display-font; text-transform: uppercase}
h4, :global(.h4)    {font-family: $body-font}
h5, :global(.h5)    {font-family: $body-font}
h6, :global(.h6)    {font-family: $body-font}
p, :global(.p)      {font-family: $body-font}
body                {font-family: $body-font}


@media screen and
(min-width: $bp-mobile) {
	h1, :global(.h1)   {font-size: $h1}
	h2, :global(.h2)   {font-size: $h2}
	h3, :global(.h3)   {font-size: $h3}
  h4, :global(.h4)   {font-size: $h4}
  h5, :global(.h5)   {font-size: $h5}
  h6, :global(.h6)   {font-size: $h6}
	p, :global(.p)     {font-size: $p}
  body               {font-size: $p}
}
