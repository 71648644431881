/**
 *
 * Base Styling
 *
**/

@import '~style/_variables.scss';

.title-container {
  padding-left: 6vw;
  padding-right: 6vw;
}

.title {
  max-width: 760px;
  padding-left: 6%;
  padding-right: 6%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 1em;
}

:global(.template-container) {
  padding: 4vw 0vw 6vw;
}

:global(.template-display) p,
:global(.template-display) li,
:global(.template-display) h1,
:global(.template-display) h2,
:global(.template-display) h3,
:global(.template-display) h4,
:global(.template-display) h5,
:global(.template-display) h6 {
  box-sizing: content-box;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  padding-left: 4vw;
  padding-right: 4vw;
}

:global(.template-display) h2 {
  text-align: center;
}

:global(.template-display) li {
  list-style: disc;
  list-style-position: inside
}

:global(.template-display) figure {
  text-align: center;
  width: 100%;
  margin: 0;
}

:global(.template-display) img {
  width: 100%;
  margin-top: 2em;
  margin-bottom: 2em;
}

:global(.template-display) h2,
:global(.template-display) h3,
:global(.template-display) h4,
:global(.template-display) h5,
:global(.template-display) h6 {
  margin-top: .6em;
}

:global(.template-display) h2:first-child,
:global(.template-display) h3:first-child,
:global(.template-display) h4:first-child,
:global(.template-display) h5:first-child,
:global(.template-display) h6:first-child {
  margin-top: 0;
}

@media screen and
(min-width: $bp-tablet-small) {
  :global(.template-display) p,
  :global(.template-display) li,
  :global(.template-display) h1,
  :global(.template-display) h2,
  :global(.template-display) h3,
  :global(.template-display) h4,
  :global(.template-display) h5,
  :global(.template-display) h6 {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and
(min-width: $bp-tablet-small) {
  :global(.template-container) {
    padding-top: 32px;
    padding-bottom: 48px;
  }
}

// HACKS 

:global(.about) > div > div {
  background-position: top;
}