/*
 * VARIABLES
 *
 * Global sizes and colors that can be used in every component
 *
 */

$body-font: 'Libre Baskerville', times, serif;
$display-font: 'Anton', helvetica, arial, sans-serif;


/* ============================================================ *\
|* Colors
\* ============================================================ */

$white:             #FFF;
$gray-steel:        #EAEAEA;
$gray:              #DADADA;
$gray-shade:        #CACACA;
$gray-medium:       #AAA;
$gray-gravel:       #4D4D4D;
$black:             #292857;
$blue-electric:     #99CCFF;
$secondary:         #167BFF;
$secondary-shade:   #1B4AA0;
$pop:               #EAB037;
$pop-shade:         #BB891E;
$blue-midnight:     #00507C;
$green-good:        #39b54a;

/* ============================================================ *\
|* Breakpoints (bp)
\* ============================================================ */

$bp-mobile:            450px;
$bp-mobile-large:      640px;
$bp-tablet-small:      720px;
$bp-tablet:            1000px;
$bp-monitor-small:     1200px;
$bp-monitor-medium:    1400px;

/* ============================================================ *\
 * Lengths
\* ============================================================ */

$h1-mobile:           7vw;
$h2-mobile:           6vw;
$h3-mobile:           5vw;
$h4-mobile:           4vw;
$h5-mobile:           3vw;
$h6-mobile:           5vw;
$p-mobile:            4vw;

$h1:                  32px;
$h2:                  24px;
$h3:                  18px;
$h4:                  16px;
$h5:                  12px;
$h6:                  20px;
$p:                   16px;
