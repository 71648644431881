/**
 * Input
 */

@import '_mixins.scss';

form {
  outline: none;
}

:global(.button),
button {
  font-family: $body-font;
  background-color: $secondary;
  padding: .75em 1.25em;
  line-height: 1em;
  vertical-align: sub;
  color: $white;
  display: inline-block;
  border-radius: 4px;
  outline: none;
  border: 0px solid transparent;
  @include smoothness;
}

:global(.button:hover),
:global(.button:focus),
button:hover,
button:focus {
  background: $blue-electric;
  transform: scale(1.05);
}

input[type=text],
input[type=email],
input[type=tel],
select {
  outline: none;
  padding: .4em .3em;
  line-height: 1em;
  border-style: solid;
  border: 1px solid $gray;
  color: $gray-gravel;
  border-radius: 3px;
  transition: .2s border-color;
}

input[type=text]:hover,
input[type=text]:focus,
input[type=email]:hover,
input[type=email]:focus,
input[type=tel]:hover,
input[type=tel]:focus,
select:hover,
select:focus {
  border-color: $pop;

}

input[type=text]::placeholder,
input[type=email]::placeholder,
input[type=tel]::placeholder {
  color: $gray;
}


textarea {
  outline: none;
  padding: .4em .3em;
  border: 1px solid $gray;
  color: $gray-gravel;
  border-radius: 3px;
  transition: .2s border-color;
}

textarea:hover,
textarea:focus {
  border-color: $pop;
}
